import React, { useState, useEffect, useRef } from 'react';

const fields = [
  {
    id: 'email',
    name: 'email',
    type: 'email',
    validation: {
      required: true,
      regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      errorMessage: 'Invalid email address'
    }
  },
  {
    id: 'password',
    name: 'password',
    type: 'password',
    validation: {
      required: true,
      minLength: 8,
      maxLength: 20,
      errorMessage: 'Password must be between 8-20 characters'
    }
  },
  {
    id: 'firstName',
    name: 'firstName',
    type: 'text',
    validation: {
      required: true,
      regex: /^[a-zA-Z\s]*$/,
      errorMessage: 'First name can only contain letters'
    }
  },
  {
    id: 'lastName',
    name: 'lastName',
    type: 'text',
    validation: {
      required: true,
      regex: /^[a-zA-Z\s]*$/,
      errorMessage: 'Last name can only contain letters'
    }
  },
  {
    id: 'phone',
    name: 'phone',
    type: 'tel',
    validation: {
      required: false,
      regex: /^\+?[\d\s-]{10,}$/,
      errorMessage: 'Invalid phone number format. It should be at least 10 digits long'
    }
  },
  {
    id: 'age',
    name: 'age',
    type: 'number',
    validation: {
      required: true,
      min: 18,
      max: 120,
      errorMessage: 'Age must be between 18-120'
    }
  },
  {
    id: 'website',
    name: 'website',
    type: 'url',
    validation: {
      required: false,
      regex: /^https?:\/\/.+\..+/,
      errorMessage: 'Invalid website URL'
    }
  },
  {
    id: 'address1',
    name: 'address1',
    type: 'text',
    validation: {
      required: true,
      errorMessage: 'Address is required'
    }
  },
  {
    id: 'address2',
    name: 'address2',
    type: 'text',
    validation: {
      required: false
    }
  },
  {
    id: 'city',
    name: 'city',
    type: 'text',
    validation: {
      required: true,
      errorMessage: 'City is required'
    }
  },
  {
    id: 'zipCode',
    name: 'zipCode',
    type: 'text',
    validation: {
      required: true,
      regex: /^\d{5}(-\d{4})?$/,
      errorMessage: 'Invalid ZIP code'
    }
  },
  {
    id: 'employeeId',
    name: 'employeeId',
    type: 'text',
    validation: {
      required: false,
      regex: /^[A-Z]{2}\d{4}$/,
      errorMessage: 'Employee ID format: XX0000'
    }
  },
  {
    id: 'salary',
    name: 'salary',
    type: 'number',
    validation: {
      required: false,
      min: 0,
      max: 1000000,
      errorMessage: 'Salary must be between 0-1000000'
    }
  },
  {
    id: 'department',
    name: 'department',
    type: 'text',
    validation: {
      required: true,
      errorMessage: 'Department is required'
    }
  },
  {
    id: 'position',
    name: 'position',
    type: 'text',
    validation: {
      required: true,
      errorMessage: 'Position is required'
    }
  },
  {
    id: 'startDate',
    name: 'startDate',
    type: 'date',
    validation: {
      required: true,
      errorMessage: 'Start date is required'
    }
  },
  {
    id: 'emergencyContact',
    name: 'emergencyContact',
    type: 'text',
    validation: {
      required: true,
      errorMessage: 'Emergency contact is required'
    }
  },
  {
    id: 'emergencyPhone',
    name: 'emergencyPhone',
    type: 'tel',
    validation: {
      required: true,
      regex: /^\+?[\d\s-]{10,}$/,
      errorMessage: 'Invalid emergency phone number'
    }
  },
  {
    id: 'socialSecurity',
    name: 'socialSecurity',
    type: 'text',
    validation: {
      required: true,
      regex: /^\d{3}-\d{2}-\d{4}$/,
      errorMessage: 'Invalid SSN format (XXX-XX-XXXX)'
    }
  },
  {
    id: 'driversLicense',
    name: 'driversLicense',
    type: 'text',
    validation: {
      required: false,
      regex: /^[A-Z]\d{7}$/,
      errorMessage: 'Invalid drivers license format'
    }
  },
  {
    id: 'education',
    name: 'education',
    type: 'text',
    validation: {
      required: false
    }
  },
  {
    id: 'certifications',
    name: 'certifications',
    type: 'text',
    validation: {
      required: false
    }
  },
  {
    id: 'skills',
    name: 'skills',
    type: 'text',
    validation: {
      required: false
    }
  },
  {
    id: 'languages',
    name: 'languages',
    type: 'text',
    validation: {
      required: false
    }
  },
  {
    id: 'references',
    name: 'references',
    type: 'text',
    validation: {
      required: false
    }
  },
  {
    id: 'bio',
    name: 'bio',
    type: 'textarea',
    validation: {
      required: false,
      maxLength: 1000,
      errorMessage: 'Bio cannot exceed 1000 characters'
    }
  },
  {
    id: 'twitter',
    name: 'twitter',
    type: 'text',
    validation: {
      required: false,
      regex: /^@[A-Za-z0-9_]{1,15}$/,
      errorMessage: 'Invalid Twitter handle'
    }
  },
  {
    id: 'linkedin',
    name: 'linkedin',
    type: 'url',
    validation: {
      required: false,
      regex: /^https:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/,
      errorMessage: 'Invalid LinkedIn URL'
    }
  },
  {
    id: 'github',
    name: 'github',
    type: 'text',
    validation: {
      required: false,
      regex: /^[A-Za-z0-9-]{1,39}$/,
      errorMessage: 'Invalid GitHub username'
    }
  },
  {
    id: 'projectCount',
    name: 'projectCount',
    type: 'number',
    validation: {
      required: false,
      min: 0,
      max: 100,
      errorMessage: 'Project count must be between 0-100'
    }
  },
  {
    id: 'yearsExperience',
    name: 'yearsExperience',
    type: 'number',
    validation: {
      required: false,
      min: 0,
      max: 50,
      errorMessage: 'Years of experience must be between 0-50'
    }
  }
];

function formHandler(data, fields) {
  const errors = {};

  fields.forEach((field) => {
    const value = data[field.name];
    const { validation } = field;

    if (!validation) return;

    // Check required
    if (validation.required && !value) {
      errors[field.name] = validation.errorMessage || 'This field is required';
      return;
    }

    // Skip other validations if empty and not required
    if (!value) return;

    // Check min length
    if (validation.minLength && value.length < validation.minLength) {
      errors[field.name] = validation.errorMessage || `Minimum length is ${validation.minLength}`;
      return;
    }

    // Check max length
    if (validation.maxLength && value.length > validation.maxLength) {
      errors[field.name] = validation.errorMessage || `Maximum length is ${validation.maxLength}`;
      return;
    }

    // Check regex pattern
    if (validation.regex && !validation.regex.test(value)) {
      errors[field.name] = validation.errorMessage || 'Invalid format';
    }
  });

  // Return errors if validation failed
  if (Object.keys(errors).length > 0) {
    return errors;
  }

  // Submit form if validation passed
  return null;
}

export default function DynamicForm() {
  const [errors, setErrors] = useState({});
  const refs = useRef({}); // Store refs for each input field

  // Submit the form
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    const errors = formHandler(data, fields);
    if (errors) {
      setErrors(errors);
    }

    if (!errors) {
      alert('Form submitted successfully');
      // Clean the form
      e.target.reset();
      // Clear the errors
      setErrors({});
    }
  };

  // Focus on the first input with an error
  useEffect(() => {
    const firstErrorField = Object.keys(errors || {}).find((key) => errors[key]);
    if (firstErrorField && refs.current[firstErrorField]) {
      refs.current[firstErrorField].focus();
    }
  }, [errors]);

  return (
    <form method="post" onSubmit={handleSubmit} className="w-96 p-10">
      {fields.map((field) => (
        <div key={field.id} className="form-group mb-2">
          <label htmlFor={field.id}>{field.name}</label>
          <input
            type={field.type}
            id={field.id}
            name={field.name}
            ref={(el) => (refs.current[field.name] = el)} // Assign ref
            className={`block w-full rounded-md shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5 border-2 h-10 p-2 text-lg ${
              errors[field.name]
                ? 'border-red-500 focus:border-red-500 focus:ring-red-500'
                : 'border-gray-300 focus:border-blue-500 focus:ring-blue-500'
            }`}
          />
          {errors[field.name] && <p className="text-red-500 text-sm mt-1">{errors[field.name]}</p>}
        </div>
      ))}
      <button type="submit" className="bg-blue-500 text-white p-2 rounded-md">
        Submit
      </button>
    </form>
  );
}
